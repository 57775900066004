import * as React from "react";
import { navigate } from 'gatsby';
import { useAuth0 } from '../../hooks/use-auth0';

const CallbackHandler = () => {
    const { authProvider } = useAuth0();
    React.useEffect(() => {
        async function callback() {
            await authProvider.handleCallback();
        }
        callback().then(() => {
            navigate('/admin');
        });
    }, [authProvider]);

    return (<>
        <h1>Logging In</h1>
    </>)
};

export default CallbackHandler;

